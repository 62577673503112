<template>
  <v-main>
    <v-container fluid>
      <BaseBreadcrumb admin />
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="7">
          <div class="mb-2">
            <v-btn
              class="text-capitalize"
              @click="tab = 'users'"
              :outlined="tab !== 'users'"
              color="primary"
              >Users</v-btn
            >
            <v-btn
              class="text-capitalize ml-1"
              @click="tab = 'organizations'"
              :outlined="tab !== 'organizations'"
              color="primary"
              >Organizations</v-btn
            >
            <v-btn
              class="text-capitalize ml-1"
              @click="tab = 'cpi'"
              :outlined="tab !== 'cpi'"
              color="primary"
              >CPI</v-btn
            >
          </div>
          <UserList v-if="tab === 'users'"></UserList>
          <Organizations v-else-if="tab === 'organizations'" />
          <CpiManage v-else-if="tab === 'cpi'" />
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="5">
          <v-card hover>
            <v-card-title> Messages </v-card-title>
            <v-card-text>
              <message-list></message-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  components: {
    UserList: () => import('@/components/Admin/UserList'),
    MessageList: () => import('@/components/Admin/MessageList'),
    BaseBreadcrumb: () => import('../../components/Core/Base/BreadCrumb.vue'),
    Organizations: () => import('../../components/Admin/Organizations.vue'),
    CpiManage : () => import("@/components/Admin/Cpi/Manage.vue")
  },
  metaInfo: {
    title: 'Admin'
  },
  data() {
    return {
      tab: 'users'
    };
  },
  mounted() {}
};
</script>

<style></style>
