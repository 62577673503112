import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"admin":""}}),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"7"}},[_c('div',{staticClass:"mb-2"},[_c(VBtn,{staticClass:"text-capitalize",attrs:{"outlined":_vm.tab !== 'users',"color":"primary"},on:{"click":function($event){_vm.tab = 'users'}}},[_vm._v("Users")]),_c(VBtn,{staticClass:"text-capitalize ml-1",attrs:{"outlined":_vm.tab !== 'organizations',"color":"primary"},on:{"click":function($event){_vm.tab = 'organizations'}}},[_vm._v("Organizations")]),_c(VBtn,{staticClass:"text-capitalize ml-1",attrs:{"outlined":_vm.tab !== 'cpi',"color":"primary"},on:{"click":function($event){_vm.tab = 'cpi'}}},[_vm._v("CPI")])],1),(_vm.tab === 'users')?_c('UserList'):(_vm.tab === 'organizations')?_c('Organizations'):(_vm.tab === 'cpi')?_c('CpiManage'):_vm._e()],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"5"}},[_c(VCard,{attrs:{"hover":""}},[_c(VCardTitle,[_vm._v(" Messages ")]),_c(VCardText,[_c('message-list')],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }